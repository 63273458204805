import rap from "./assets/hip hop havoc-min.png";
import Wow from "./assets/wings of words-min.png";
import ekant from "./assets/ekant-min.png";
import amp from "./assets/amplify-min.png";
import sync from "./assets/SYNC (3)-min.png";
import ant from "./assets/antakshari-min.png";
import art from "./assets/artractive-min.png";
import cb from "./assets/camera bondi final-min.png";
import cs from "./assets/corral scribbles final-min.png";
import lc from "./assets/Lights Camera Action - Poster-min.png";
import re from "./assets/REELVERSE - Poster-min.png";
const eventData = [
  {
    id: 1,
    imgSrc: rap,
    guide: `Each round must not exceed 1-3 minutes, with an overall event limit of 4 minutes (3+1) 
Offensive language or inappropriate content is strictly prohibited.
 The Backing Track(if any) must be submitted a day prior to the event 
Rappers will be evaluated based on lyrical content, flow, delivery, and creativity. 
Respect towards opponents and judges is mandatory, failure to comply will result in disqualification.
 The registration fee for Rap-Battle is Rs.70. (non-refundable).
      `,
    headline: "Hip-Hop Havoc",
    cdName1: "Gairika Karmakar ",
    cdNumber1: "90070 99634",
    cdName2: "Koustav Adhikar",
    cdNumber2: "70472 48846",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLSd_qK_kEb8ixqKfdkoFngrVA2tUa2-79hZxubxhckg2df3Qdg/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 2,
    imgSrc: Wow,
    guide: `Rules and Regulations:
The program will commence promptly at 9:30 am.
Participants can choose to recite a piece of writing in English, Hindi, or Bengali, or they can recite their own compositions in the mentioned languages.
There is a rigorous 4-minute time limit.
Evaluations will be made exclusively based on the participant's performance and presentation.
The judges' verdict shall be considered final.
Wings of Words registration fee is Rs. 70. (non-refundable).
         `,
    headline: "Wings of Words",
    cdName1: "Ahana Saha ",
    cdNumber1: "7047295548",
    cdName2: "Adrija Paul ",
    cdNumber2: "7029044462",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSdZsh39mmgY84fp759RYFVwhI-1WS-_b4slp84z0Ep0ob1t_A/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 3,
    imgSrc: ekant,
    guide: `Rules and Regulations:
The program will commence promptly at 9:30 am.
Another individual may accompany them with a musical instrument or an Electronic Tanpura.
The Backing Track(if any) must be submitted a day prior to the event
There's a strict 4 minutes time limit, including 1 minute for sound check and 3 minutes for performance.
Mashups are not allowed.
Evaluation is solely based on the vocalist's performance, with the judges' verdict to be conclusive final.
The registration fee for Ekant is Rs. 100 (non-refundable).
          `,
    headline: "Ekant",
    cdName1: "Shrestho Chakraborty",
    cdNumber1: "9073641090",
    cdName2: "Gairika Karmakar",
    cdNumber2: "9007099634",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSd7QVDIM54y1SdkEBpd-0pGwT_41eXSIFR1W85yxBMxPcTTiw/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: sync,
    imgSrc: sync,
    guide: `. Rules and Regulations:
The program will commence promptly at 9.30 am.
The participating band is expected to bring all of the musical equipment. There will only be a five-piece drum kit available.
Bands must perform within 15 minutes time limit, including a 3 minutes sound check and 12 minutes performance.
Each band must have a minimum of 4 members and a maximum of 8 members to be eligible for participation.
Pre-recorded tracks are strictly prohibited.
Original composition can also be performed.
Any profane or inappropriate behavior will result in immediate disqualification of the band.
The judges’ verdict shall be considered as final, depending upon the band’s performance and time management.
The Sync registration fee is Rs. 600.(non-refundable)
To ensure the safety of performers and equipment, participants are strictly prohibited from placing their foot on the stage monitors during their performance. Any violation of this rule may lead to penalties or disqualification.
          `,
    headline: "Sync",
    cdName1: "Shrestho Chakraborty ",
    cdNumber1: "9073641090",
    cdName2: "Koustav Adhikari",
    cdNumber2: "7047248846",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSe5itCaB379_be2KyFNDfwFDGgPB1ZPlzRMmUTkhpNRzhujQw/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 5,
    imgSrc: amp,
    guide: `Rules and Regulations
 The program will commence promptly at 9:30 am.
Participants are required to bring all their musical instruments. The performance must adhere to a 4-minute time limit, including 1 minute for sound check and 3 minutes for the performance.
The Backing Track (if any) must be submitted a day prior to the event.
The decision-making process will solely be based on the performance exhibited by the participants. The verdict rendered by the judges shall be regarded as conclusive and final.
The registration fee for Amplify is Rs. 70. (non- refundable)
          `,
    headline: "Amplify",
    cdName1: "Gairika Karmakar",
    cdNumber1: "9007099634",
    cdName2: "Sourish Guha",
    cdNumber2: "8100394782",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSfzCUIda89N2RDc-wI1wGSmdH4byLLejHaRntK-bx1hjrx-pw/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 6,
    imgSrc: ant,
    guide: `	This is an on-the-spot competition where three teams compete in each round, with the winner receiving a CASH PRIZE right away.
          	There will be two people in each team. 
          	The Antakshari standard rules will apply to the event, along with a few amendments. Details about the modifications will be announced during the event. 
            Teams will be instantly eliminated from the competition in case of any if any misconduct or malpractice. 
            The registration fee is Antakshari Rs. 50. (non refundable).
          `,
    headline: "Antakshari",
    cdName1: "Gairika Karmakar",
    cdNumber1: "9007099634",
    cdName2: "Koustav Adhikari",
    cdNumber2: "7047248846",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "",
    btnName: "ON SPOT REGISTRATION",
  },
  {
    id: 7,
    imgSrc: art,
    guide: `	1.This is an online event.
2.The participant will have to upload their poster according to the theme.
3.If any plagiarism is found , it will lead to immediate disqualification.
4.Size limit of the poster is 15mb. The format should be .png
5.Decisions made by the judges will be deemed as final.
6.Registration Fees for Artractive is Rs. 50 (non refundable)
7.The theme of the event is- CONCERT VIBES
8.The last date for submission is 10th April.
Design an eye-catching, high-energy poster for a dream music festival or an imaginary concert lineup. Take inspiration from the famous concert flyers moving around the town.
          `,
    headline: "Artractive",
    cdName1: "Sourish Guha",
    cdNumber1: "8100394782",
    cdName2: "Riyanshu Baskey",
    cdNumber2: "6291337506",
    deadline: "10th April",
    finalDate: "10th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLSfzJcp6slfOnL1-zJ10CslEyzDTtyMGBxTm7c7ErsdqpLnc-g/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 8,
    imgSrc: cb,
    guide: `	This is a LIVE event and the submissions will be accepted through a google drive link which will be shared in due time.
The participant will have to upload their photograph according to the theme. The photo can be taken in any medium(mobile/DSLR) but it must be an original click.
Photograph manipulation will not be allowed. However, basic editing can be done.
The decision made by the judges will be deemed as final.
The registration fees for Camera Bondi is Rs. 50 (non refundable)
The theme of the event is- NEON NOTES
The last date for submission is 10th April.  
          `,
    headline: "Camera বন্দী",
    cdName1: "Adrija Paul",
    cdNumber1: "7029044462",
    cdName2: "Gairika Karmakar",
    cdNumber2: "9007099634",
    deadline: "12th April",
    finalDate: "12th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLScYz4EhK7IMj_xqRlnldZUmbHYXLmVeWQkvON5BFgRC2isVRw/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 9,
    imgSrc: cs,
    guide: `	1.This is an online event.
2.The participant will have to upload their writeup according to the theme.
3.If any plagiarism is found , it will lead to immediate disqualification.
4.Size limit of the poster is 15mb. The format should be .pdf
5.Decisions made by the judges will be deemed as final.
6.The theme of the event is- SOUND TRACK OF LIFE
7.The last date for submission is 10th April.
Every life has its own rhythm — a unique blend of highs and lows, fast beats and slow melodies. This theme invites participants to explore how music mirrors life’s journey. Through stories, poems, or essays, let your words flow like music, capturing life’s rhythm
6.Registration Fees for Corral Scribbles is Rs. 50 (non refundable)
          `,
    headline: "CORRAL SCRIBBLES",
    cdName1: "Adrija Paul",
    cdNumber1: "7029044462",
    cdName2: "Ahana Saha",
    cdNumber2: "7047295548",
    deadline: "10th April",
    finalDate: "10th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLScVpK-RQ-9622wVnL-IjzNVR4YAk7lEfmDD5t0KEysJzwEKEQ/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 10,
    imgSrc: lc,
    guide: `	1. This is an online event.
2.The participants will have to upload their video according to the theme. The time limit of the video is strictly 3 minutes and must be within 500 MB size.
3.The decision made by the judges will be deemed as final.
4.Registration Fees for Lights Camera Action is Rs. 50 (non refundable)
5.The theme of the event is- CINEMATIC MASTERPIECE
6.The last date for submission is 10th April.
Showcase the best of your creativity and skill — your most powerful story, your sharpest visuals, and your most compelling emotions. Let this be the video that defines your artistic vision.
          `,
    headline: "LIGHTS CAMERA ACTION",
    cdName1: "Koustav Adhikari",
    cdNumber1: "7430969174",
    cdName2: "Riyanshu Baskey",
    cdNumber2: "6291337506",
    deadline: "10th April",
    finalDate: "10th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLSek6lmA5q1cFXpgSAha_vMwOESOJZEzDqgFu3XAkCtx_HdNjA/viewform",
    btnName: "REGISTER HERE",
  },
  {
    id: 11,
    imgSrc: re,
    guide: ` This is an online event.
The participant will have to upload their reel according to the theme.
If any plagiarism is found , it will lead to immediate disqualification.
Size limit of the reel is 100mb. 
Aspect ratio should be 9:16
Decisions made by the judges will be deemed as final.
Registration Fees for Reelverse is Rs. 50 (non refundable)
The last date for submission is 10th April.  
5. The theme of the event is- MELODIC MEMORIES
Music has a way of bringing memories to life — a single tune can transport us to moments of joy, love, heartbreak, or nostalgia. Through this theme, capture how music intertwines with memories, shaping stories that unfold in every beat and lyric. Let each reel be a visual journey where sound and emotion create unforgettable echoes of the past.
          `,
    headline: "REELVERSE",
    cdName1: "Adrija Paul",
    cdNumber1: "7029044462",
    cdName2: "Shrestho Chakraborty",
    cdNumber2: "9073641090",
    deadline: "10th April",
    finalDate: "10th April",
    finalTime: "9:30 AM",
    finalVenue: "MSIT Campus",
    formUrl: "https://docs.google.com/forms/d/e/1FAIpQLSdQO0Q_bbgH6kB9OW3qwpItbSrA18mnDooT4Yd4NXAZCu7hUQ/viewform",
    btnName: "REGISTER HERE",
  },
];

export { eventData };
